@import url('variables.css');

/* Day */

body.day,
body.day a {
  color: var(--color-border-night);
}

body.day .description,
body.day .timeline-item p,
body.day .contact-method {
  background-color: var(--color-border-day);
}

body.day .visualization-container {
  border-color: var(--color-border-day);
}

body.day .loading {
  color: var(--color-border-day);
}

/* Night */

body.night,
body.night a {
  color: var(--color-border-day);
}

body.night .description,
body.night .timeline-item p,
body.night .contact-method {
  background-color: var(--color-border-night);
}

body.night .visualization-container{
  border-color: var(--color-border-night);
}

body.night .loading {
  color: var(--color-border-night);
}