@import url('variables.css');

.name,
.description {
  margin-bottom: 1rem;
  margin-top: 0;
}

.name {
  font-family: var(--font-serif);
  font-size: min(17vw, 6em);
  line-height: 0.85em;
}

.description {
  font-size: 1.25em;
}

.timeline,
.contact-info {
  list-style-type: none;
  padding-left: 0;
}

.contact-info {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5em;
}

.description,
.timeline-item p,
.contact-method {
  display: inline-block;
  margin-bottom: 0;
  margin-top: 0;
  padding: calc(var(--size-text-padding) / 2) var(--size-text-padding);
}

.timeline-item p:first-of-type {
  padding-top: var(--size-text-padding);
}

.timeline-item p:last-of-type {
  padding-bottom: var(--size-text-padding);
}

@media (max-width: 768px) {
  .personal-info {
    padding: calc(var(--size-border-body) * 2) var(--size-border-body);
  }

  .description {
    font-size: 1.15em;
  }

  .timeline-item strong {
    display: block;
  }
}
