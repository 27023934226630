@import url('variables.css');

.visualization-container {
  border-width: var(--size-border-body);
  border-style: solid;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100dvh;
  overflow: hidden;
  transition: background-color 1s ease, border-color 0.5s ease;
  z-index: -1;
}

.visualization-canvas {
  width: 100%;
  height: 100%;
}

.visualization-canvas canvas {
  display: block;
}

.visualization-canvas .loading {
  font-size: 0.5em;
  position: absolute;
  z-index: -2;
}